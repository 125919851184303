<template>
  <el-dialog
    :title="'【异常处理】'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" size="small" label-width="120px">
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="异常类型" prop="name">
            <el-input v-model="dataForm.type"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
      <el-col :span="24">
          <el-form-item label="异常处理方案" prop="cardNo">
            <el-input v-model="dataForm.method" type="textare"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="异常附件" prop="cardFileList">
            <el-upload  style="margin-top: -30px;"
              action="/upload/img"
              :on-preview="handlePreview"
              :before-remove="removeFileList"
              :file-list="cardFileList"
              list-type="picture">
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="异常处理状态" prop="dwellCity">
            <el-input v-model="dataForm.ycStatus"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" :rows="5" v-model="dataForm.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="状态" prop="maxLoad">
              <el-select class="item-choose" v-model="dataForm.status" style="width: 100%;" filterable clearable placeholder="请选择">
                <el-option label="请选择" value=""></el-option>
                <el-option label="已处理" value="已处理"></el-option>
                <el-option label="已处理" value="已处理"></el-option>
              </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        type: null,
        method: null,
        ycStatus: null,
        remark: null,
        status: null
      },
      dialogImageUrl: '',
      dialogVisible: false,
      cardFileList: [],
      proofFileList: []
    }
  },
  computed: {
    dictInfoMap: {
      get () { return this.$store.state.common.dictInfoMap }
    }
  },
  methods: {
    clearFileList () {
      this.cardFileList = []
      this.proofFileList = []
      this.dataForm.cardFileList = []
      this.dataForm.proofFileList = []
    },
    initFileList (dto) {
      if (dto.cardFileList) {
        this.dataForm.cardFileList = dto.cardFileList
        for (let i = 0; i < dto.cardFileList.length; i++) {
          this.cardFileList.push({name: dto.cardFileList[i].name, url: dto.cardFileList[i].url})
        }
      }
      if (dto.proofFileList) {
        this.dataForm.proofFileList = dto.proofFileList
        for (let i = 0; i < dto.proofFileList.length; i++) {
          this.proofFileList.push({name: dto.proofFileList[i].name, url: dto.proofFileList[i].url})
        }
      }
    },
    init (id) {
      this.dataForm.id = id
      this.visible = true
      this.clearFileList()
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/driver/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data) {
            this.dataForm.type = data.type
            this.dataForm.method = data.method
            this.dataForm.ycStatus = data.ycStatus
            this.dataForm.remark = data.remark
            this.dataForm.status = data.status
            this.initFileList(data)
          }
        })
      }
    },
    // 图片查看
    handlePreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    removeFileList () {
      return false
    }
  }
}
</script>
